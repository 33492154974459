import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import { toast } from 'react-toastify'
import copypatseIcon from './copypatse.png';

import styles from './GenerateCode.module.scss'

export default function GenerateCode() {
  const referral_code = useSelector((state) => state.modalSlice.referral_code)


  const referred = useSelector((state) => state.modalSlice.referred)
  const referrer_address = useSelector((state) => state.modalSlice.referrer_address)
  const _user = useSelector((state) => state.modalSlice.user)



   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = `https://t.me/OculusSolana_Bot?start=${referral_code}`

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);

        toast.success(`Referral link with code ${referral_code} copied to clipboard`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
  }


console.log('referred', referred)


let refz = referred[0]?.referred || []

  return (
    <div className={styles.container}>

      <div className={styles.profileCard}>

{
  referral_code !== null
    ? (        <div className={styles.profileHeader}>
          <div className={styles.profileTitle}>REFERRAL CODE: {referral_code}</div>
          <div className={styles.iconContainer}>
            <img       onClick={() => handleCopyToClipboard()} 
src={copypatseIcon} className={styles.robotIcon} />
          </div>
        </div>)
    : (        <div className={styles.profileHeader}>
          <div className={styles.profileTitle}>REFERRAL CODE: ——————————</div>
         
        </div>)
}



        <div className={styles.profileContainer}>
          <div className={styles.profileRow}>
            <div className={`${styles.value} ${styles.bag}`}>

            {
  referral_code !== null
    ? refz.length > 0 ? (

                  <ul className={styles.list}>
                {refz.map((referral, index) => (
                    <li key={index}>
                        {referral.telegram_name}
                    </li>
                ))}
            </ul>

            ) : ''
    : 'referral code will be generated after you create your first profile'

  }
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}