import React from 'react';
import s from './ShowFilters.module.scss';
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import { useActions } from '../../../../hooks/use-actions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import robotIcon from './robot.png';
import settingsIcon from './settings.png';

import robot2Icon from './robot2.png';
import { BiX } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'

const ProfileCards = () => {
    const nav = useNavigate()

    const { editQuery } = useActions()

  const { savedQueries = [], user, autobuy, wallets, tier  } = useSelector((state) => state.modalSlice); // Ensures savedQueries is an array by default
  const { deleteQuery, editAutoBuyQuery, deleteAutoBuy, toggleFilter, toggleAutoBuy } = useActions();
  console.log('user', user)
  console.log('savedQueries', savedQueries)
  const activeQueries = savedQueries.filter(query => !query.deleted);
  // console.log('activeQueries', activeQueries)
  const handleToggle = (item, isPaused) => {

    let data = {
        id: item.id,
        telegram_id: user.telegram_id,
        toggle: !item.paused
    }

    toggleFilter(data)

    toast.success(
      data.toggle
        ? `'${item.name}' Paused`
        : `'${item.name}' Enabled`,
      {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
    
  }


  const toggleAutoBuyQuery = async (item, z) => {

    let data = {
        id: item.id,
        telegram_id: user.telegram_id,
        toggle: !item.paused,
        filter: z
    }

    if (z.paused === false) {

      await toggleAutoBuy(data)

      toast.success(`'${item.name}' ${item.paused ? 'Enabled' : 'Paused'}`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

    } else {

            toast.error(`'${z.name}' needs to be unpaused first.`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

    }

  }


  const handleDelete = (id, name) => {

    let data = {
      id,
      telegram_id: user.telegram_id,
      hash: user.hash
    }

    deleteQuery(data)

    toast.success(`'${name}' Removed`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

  }

  const removeAutobuyQuery = (item, name) => {

    let data = {
      
      id: item.id,
      telegram_id: user.telegram_id,

    }

   // deleteAutoBuy(data)

        toast.success(`Removed '${data.name}' AUTOBUY`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

  }

function shortenText(text, maxLength = 20) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

/*
  if (!Array.isArray(savedQueries)) {
    return <p>Error: savedQueries is not an array</p>;
  }
*/

  const groupedQueries = savedQueries.reduce((acc, query) => {
    if (!acc[query.id]) {
      acc[query.id] = { ...query, autobuy: [...(query.autobuy || [])] };
    } else {
      acc[query.id].autobuy = [...acc[query.id].autobuy, ...(query.autobuy || [])];
    }
    return acc;
  }, {});

  console.log('groupedQueries', groupedQueries)
  
  return (
    <div className={s.container}>
      <div className={s.profilesContainer}>
        {Object.keys(groupedQueries).length === 0 ? (
          <p style={{fontSize: '13px', fontColor: '#fff'}}>No profiles found, create your first!</p>
        ) : (
          Object.values(groupedQueries).map((item) => (
            <div key={item.id} className={s.profileCard}>
              <div className={s.iconCircle}>
                <BiX size={17} color="#fff" onClick={() => handleDelete(item.id, item.name)} />
              </div>

              <div className={item.autobuy.length > 0 ? s.profileHeader : s.profileHeaderAutobuys}>
                <div className={s.profileTitle}>{shortenText(item.name)}</div>
                <div className={s.iconContainer}>
                  <img
                    src={robotIcon}
                    onClick={() => {
                      editAutoBuyQuery({
                        name: '',
                        wallets: [],
                        gas: [],
                        eth_amount: '',
                        max_slippage: '',
                        max_gas: '',
                        filterId: item.id,
                        filterName: item.name,
                      });
                      nav(`/mastercraft/show-filters/${item.id}/auto-buy`);
                    }}
                    style={{ width: '24px', height: '24px', position: 'relative', bottom: '4px', cursor: 'pointer' }}
                    alt="Auto Buy Icon"
                  />
                  <img
                    onClick={() => {
                              editQuery(item)
                              nav('/mastercraft/edit-filter-gm')
                    }}
                    src={settingsIcon}
                    alt="Settings Icon"
                    style={{ width: '16px', height: '16px', position: 'relative', cursor: 'pointer' }}
                  />
                  <label className={item.paused ? s.toggledOff : s.toggledOn}>
                    <input
                      type="checkbox"
                      className={s.toggleInput}
                      checked={!item.paused}
                      onChange={() => handleToggle(item)}
                    />
                    <span className={s.toggleIcon}></span>
                  </label>
                </div>
              </div>

              <div className={s.profileContainer}>
                {item.autobuy &&
                  item.autobuy.map((autoBuyItem, idx) => (
                    <div key={idx} className={s.profileRow}>
                      <div className={`${s.value} ${s.bag}`}>
                        {autoBuyItem.name}
                      </div>
                      <div className={`${s.value} ${s.highlight}`}>
                        <div className={s.iconContainer}>
                          <img
                            onClick={() => {
                              editAutoBuyQuery(autoBuyItem);
                             nav(`/mastercraft/show-filters/${autoBuyItem.id}/edit-auto-buy`);
                            }}
                            src={settingsIcon}
                            alt="Settings Icon"
                            style={{ width: '16px', height: '16px', position: 'relative' }}
                          />
                          <label className={autoBuyItem.paused ? s.toggledOff : s.toggledOn}>
                            <input
                              type="checkbox"
                              className={s.toggleInput}
                              checked={!autoBuyItem.paused}
                              onChange={() => toggleAutoBuyQuery(autoBuyItem, item)}
                            />
                            <span className={s.toggleIcon}></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProfileCards;
