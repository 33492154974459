import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/use-actions';
import { data } from '../filter-creator.gm.data';
import s from './Option.module.scss';

import { toast } from 'react-toastify'

export default function OptionsGM() {

      const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()

    const { savedQueries, query, user } = useSelector((state) => state.modalSlice)

  return (
    <div className={s.container}>
     
      <div className={s.full}>
        {data.options.map((option, i) => (
          <ProfileCard key={i} item={option} />
        ))}






  <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>MARKETCAP</div>
    </div>

    <div className={s.settingsContent}>
      <div className={s.settingsRow}>
        <div className={s.value}>
          <span style={{ paddingRight: '15px' }}>MIN $</span>
          <input
            type="text"
            value={query.mcap_min}
            onChange={(event) =>
              editQuery({
                ...query,
                mcap_min: event.target.value,
              })
            }
            placeholder="30000"
            className={s.inputFieldX}
          />
        </div>

        {/* Toggle Switch for mcap_min_toggle */}
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-mcap_min"
            className={s.toggleInput}
            checked={query.mcap_min_toggle}
            onChange={(event) =>
              editQuery({
                ...query,
                mcap_min_toggle: event.target.checked,
              })
            }
          />
          <label htmlFor="toggle-mcap_min" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>

      <div className={s.settingsRow}>
        <div className={s.value}>
          <span style={{ paddingRight: '15px' }}>MAX $</span>
          <input
            type="text"
            value={query.mcap_max}
            onChange={(event) =>
              editQuery({
                ...query,
                mcap_max: event.target.value,
              })
            }
            placeholder="30000"
            className={s.inputFieldX}
          />
        </div>

        {/* Toggle Switch for mcap_max_toggle */}
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-mcap_max"
            className={s.toggleInput}
            checked={query.mcap_max_toggle}
            onChange={(event) =>
              editQuery({
                ...query,
                mcap_max_toggle: event.target.checked,
              })
            }
          />
          <label htmlFor="toggle-mcap_max" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  </div>






        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>TOKEN NAME WHITELIST</div>
          </div>
          <div className={s.profileContainer}>
            <TokenWhiteList />
          </div>
        </div>


        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>TOKEN NAME BLACKLIST</div>
          </div>
          <div className={s.profileContainer}>
            <TokenBlackList />
          </div>
        </div>


        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>ADVANCED</div>
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoBuyToggle />
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoSellToggle />
          </div>
        </div>


    





      </div>

    </div>
  );
}

const ProfileCard = ({ item }) => (

  <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>{item.title}</div>
    </div>
    <div className={s.profileContainer}>
      {item.items.map((profileRow, idx) => (
        <ProfileRow key={idx} item={profileRow} />
      ))}
    </div>
  </div>

);


const SimulateAutoBuyToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  // Sync toggle state with global state
  const isSimulateBuysEnabled = query.simulateBuys || false;

  // Update both local and global state on toggle
  const handleSimulateBuysToggle = () => {
    const newValue = !isSimulateBuysEnabled;
    editQuery({ ...query, simulateBuys: newValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO BUYS</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateBuys"
            className={s.toggleInput}
            checked={isSimulateBuysEnabled}
            onChange={handleSimulateBuysToggle}
          />
          <label htmlFor="toggle-simulateBuys" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const SimulateAutoSellToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  // Sync toggle state with global state
  const isSimulateSellsEnabled = query.simulateSells || false;

  // Update both local and global state on toggle
  const handleSimulateSellsToggle = () => {
    const newValue = !isSimulateSellsEnabled;
    editQuery({ ...query, simulateSells: newValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO SELLS</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateSells"
            className={s.toggleInput}
            checked={isSimulateSellsEnabled}
            onChange={handleSimulateSellsToggle}
          />
          <label htmlFor="toggle-simulateSells" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const TokenWhiteList = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  const [inputValue, setInputValue] = useState('');
  const [whiteListTags, setWhitelistTags] = useState([]);

  useEffect(() => {
    setWhitelistTags(query.includeWords || []);
  }, [query.includeWords]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (whiteListTags.length >= 3) {

      

        return;
      }

      if (inputValue.trim() !== '' && !whiteListTags.includes(inputValue)) {
        const updatedTags = [...whiteListTags, inputValue];
        setWhitelistTags(updatedTags);
        editQuery({ ...query, includeWords: updatedTags }); // Update query
        setInputValue('');
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (whiteListTags.length >= 3) {
      // console.error('Error: You can only add up to 5 words.');

        toast.error(`You can only add up to 3 words`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


      return;
    }

    if (inputValue.trim() !== '' && !whiteListTags.includes(inputValue)) {
      const updatedTags = [...whiteListTags, inputValue];
      setWhitelistTags(updatedTags);
      editQuery({ ...query, includeWords: updatedTags }); // Update query
      setInputValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = whiteListTags.filter((tag) => tag !== tagToRemove);
    setWhitelistTags(updatedTags);
    editQuery({ ...query, includeWords: updatedTags }); // Update query
  };

  return (
    <div>
      <div className={s.footer}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Token name"
          className={s.inputField2}
        />
        <button className={s.saveButton} onClick={handleButtonClick}>
          Add
        </button>
      </div>

      <div className={s.tagList}>
        {whiteListTags.map((tag, index) => (
          <div key={index} className={s.tag}>
            {tag}
            <span className={s.deleteTag} onClick={() => handleRemoveTag(tag)}>
              &times;
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


const TokenBlackList = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  const [inputValue, setInputValue] = useState('');
  const [blackListTags, setBlacklistTags] = useState([]);

  useEffect(() => {
    setBlacklistTags(query.excludeWords || []);
  }, [query.excludeWords]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (blackListTags.length >= 3) {
        return;
      }

      if (inputValue.trim() !== '' && !blackListTags.includes(inputValue)) {
        const updatedTags = [...blackListTags, inputValue];
        setBlacklistTags(updatedTags);
        editQuery({ ...query, excludeWords: updatedTags }); // Update query
        setInputValue('');
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (blackListTags.length >= 3) {
  toast.error(`You can only add up to 3 words`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

      return;
    }

    if (inputValue.trim() !== '' && !blackListTags.includes(inputValue)) {
      const updatedTags = [...blackListTags, inputValue];
      setBlacklistTags(updatedTags);
      editQuery({ ...query, excludeWords: updatedTags }); // Update query
      setInputValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = blackListTags.filter((tag) => tag !== tagToRemove);
    setBlacklistTags(updatedTags);
    editQuery({ ...query, excludeWords: updatedTags }); // Update query
  };

  return (
    <div>
      <div className={s.footer}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Token name"
          className={s.inputField2}
        />
        <button className={s.saveButton} onClick={handleButtonClick}>
          Add
        </button>
      </div>

      <div className={s.tagList}>
        {blackListTags.map((tag, index) => (
          <div key={index} className={s.tag}>
            {tag}
            <span className={s.deleteTag} onClick={() => handleRemoveTag(tag)}>
              &times;
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


const ProfileRow = ({ item }) => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  const handleToggle = () => {
    const updatedQuery = query.selected.includes(item.id)
      ? query.selected.filter((id) => id !== item.id)
      : [...query.selected, item.id];
    editQuery({ ...query, selected: updatedQuery });
  };

  const isChecked = query.selected.includes(item.id);

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>{item.name}</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id={`toggle-${item.id}`}
            className={s.toggleInput}
            checked={isChecked}
            onChange={handleToggle}
          />
          <label htmlFor={`toggle-${item.id}`} className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};
