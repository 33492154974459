import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/use-actions';
import { data } from '../filter-creator.gm.data';
import s from './Option.module.scss';

export default function OptionsGM() {
  return (
    <div className={s.container}>
     
      <div className={s.full}>
        {data.options.map((option, i) => (
          <ProfileCard key={i} item={option} />
        ))}




{/*}

        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>TOKEN NAME WHITELIST</div>
          </div>
          <div className={s.profileContainer}>
            <TokenWhiteList />
          </div>
        </div>


        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>TOKEN NAME BLACKLIST</div>
          </div>
          <div className={s.profileContainer}>
            <TokenBlackList />
          </div>
        </div>
*/}

        <div className={s.profileCard}>
          <div className={s.profileHeader}>
            <div className={s.profileTitle}>ADVANCED</div>
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoBuyToggle />
          </div>
          <div className={s.profileContainer}>
            <SimulateAutoSellToggle />
          </div>
        </div>


    


      </div>

    </div>
  );
}

const ProfileCard = ({ item }) => (

  <div className={s.profileCard}>
    <div className={s.profileHeader}>
      <div className={s.profileTitle}>{item.title}</div>
    </div>
    <div className={s.profileContainer}>
      {item.items.map((profileRow, idx) => (
        <ProfileRow key={idx} item={profileRow} />
      ))}
    </div>
  </div>

);


const SimulateAutoBuyToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  // Sync toggle state with global state
  const isSimulateBuysEnabled = query.simulateBuys || false;

  // Update both local and global state on toggle
  const handleSimulateBuysToggle = () => {
    const newValue = !isSimulateBuysEnabled;
    editQuery({ ...query, simulateBuys: newValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO BUYS</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateBuys"
            className={s.toggleInput}
            checked={isSimulateBuysEnabled}
            onChange={handleSimulateBuysToggle}
          />
          <label htmlFor="toggle-simulateBuys" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const SimulateAutoSellToggle = () => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  // Sync toggle state with global state
  const isSimulateSellsEnabled = query.simulateSells || false;

  // Update both local and global state on toggle
  const handleSimulateSellsToggle = () => {
    const newValue = !isSimulateSellsEnabled;
    editQuery({ ...query, simulateSells: newValue });
  };

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>SIMULATE AUTO SELLS</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id="toggle-simulateSells"
            className={s.toggleInput}
            checked={isSimulateSellsEnabled}
            onChange={handleSimulateSellsToggle}
          />
          <label htmlFor="toggle-simulateSells" className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const TokenWhiteList = () => {
  const [inputValue, setInputValue] = useState('');
  const [whiteListTags, setWhitelistTags] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '' && !whiteListTags.includes(inputValue)) {
      setWhitelistTags([...whiteListTags, inputValue]);
      setInputValue('');
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (inputValue.trim() !== '' && !whiteListTags.includes(inputValue)) {
      setWhitelistTags([...whiteListTags, inputValue]);
      setInputValue(''); 
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setWhitelistTags(whiteListTags.filter(tag => tag !== tagToRemove));
  };

  return (
    <div>
      <div className={s.footer}>

          <input
            type="text"
            value={inputValue}
                 onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onClick={() => handleButtonClick()}
            placeholder="token name"
            className={s.inputField}
          />

                      <button className={s.saveButton}  onClick={handleButtonClick}>
    Add
    </button>
    


      </div>

      <div className={s.tagList}>
        {whiteListTags.map((tag, index) => (
          <div key={index} className={s.tag}>
            {tag}
            <span className={s.deleteTag} onClick={() => handleRemoveTag(tag)}>
              &times;
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const TokenBlackList = () => {
  const [inputValue, setInputValue] = useState('');
  const [blackListTags, setBlacklistTags] = useState([]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '' && !blackListTags.includes(inputValue)) {
      setBlacklistTags([...blackListTags, inputValue]);
      setInputValue('');
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (inputValue.trim() !== '' && !blackListTags.includes(inputValue)) {
      setBlacklistTags([...blackListTags, inputValue]);
      setInputValue(''); 
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setBlacklistTags(blackListTags.filter(tag => tag !== tagToRemove));
  };

  return (
    <div>
      <div className={s.footer}>

          <input
            type="text"
            value={inputValue}
                 onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onClick={() => handleButtonClick()}
            placeholder="token name"
            className={s.inputField}
          />

                      <button className={s.saveButton}  onClick={handleButtonClick}>
    Add
    </button>
    


      </div>

      <div className={s.tagList}>
        {blackListTags.map((tag, index) => (
          <div key={index} className={s.tag}>
            {tag}
            <span className={s.deleteTag} onClick={() => handleRemoveTag(tag)}>
              &times;
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};


const ProfileRow = ({ item }) => {
  const { editQuery } = useActions();
  const query = useSelector((state) => state.modalSlice.query);

  const handleToggle = () => {
    const updatedQuery = query.selected.includes(item.id)
      ? query.selected.filter((id) => id !== item.id)
      : [...query.selected, item.id];
    editQuery({ ...query, selected: updatedQuery });
  };

  const isChecked = query.selected.includes(item.id);

  return (
    <div className={s.profileRow}>
      <div className={`${s.value} ${s.bag}`}>{item.name}</div>
      <div className={`${s.value} ${s.highlight}`}>
        <div className={s.toggleSwitch}>
          <input
            type="checkbox"
            id={`toggle-${item.id}`}
            className={s.toggleInput}
            checked={isChecked}
            onChange={handleToggle}
          />
          <label htmlFor={`toggle-${item.id}`} className={s.toggleLabel}>
            <span className={s.toggleIcon}></span>
          </label>
        </div>
      </div>
    </div>
  );
};
