import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/use-actions';
import styles from './ShowBags.module.scss';
import robot2Icon from './robot2.png';
import copypatseIcon from './copypatse.png';
import { BiX } from 'react-icons/bi'
import { toast } from 'react-toastify'
import moment from 'moment';

export default function ShowBags() {
  const bags = useSelector((state) => state.modalSlice.bags);
  const { getBags } = useActions();
  const { deleteBagQuery, deleteBag } = useActions() // api
  const { user } = useSelector((state) => state.modalSlice)

const [openCardIds, setOpenCardIds] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
              await getBags({telegram_id: user.telegram_id});

      } catch (error) {
        console.error(error);
      }
    };
    fetchDataAsync();
    const intervalId = setInterval(fetchDataAsync, 30000);
    return () => clearInterval(intervalId);
  }, [getBags]);

  const shortenAddress = (address) => {
    if (!address || address.length < 10) return address;
    const firstPart = address.slice(0, 6);
    const lastPart = address.slice(-6);
    return `${firstPart}....${lastPart}`;
    return address
  };

  const groupAndSortByFilterIdDesc = (dataArray) => {
    const groupedData = dataArray.reduce((acc, item) => {
      const { filter_id } = item;
      if (!acc[filter_id]) acc[filter_id] = [];
      acc[filter_id].push(item);
      return acc;
    }, {});
    Object.keys(groupedData).forEach((filterId) => {
      groupedData[filterId].sort((a, b) => b.id - a.id);
    });
    return Object.keys(groupedData)
      .map((filterId) => ({
        filter_id: filterId,
        items: groupedData[filterId],
      }))
      .sort((a, b) => b.filter_id - a.filter_id);
  };


// console.log('bags', bags)

  const sortedBags = groupAndSortByFilterIdDesc(bags);
function truncateString(str) {
  if (str.length > 25) {
    return str.slice(0, 25) + '...';
  }
  return str;
}


  const handleDelete = (id, name) => {


    let data = {
        id,
        telegram_id: user.telegram_id,
        hash: user.hash
    }
        deleteBag(data)
        deleteBagQuery(data)
        toast.success(`Removed ${name}'s Bag`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
  };


/*

    color: #4cfaff;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);

*/

   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (addy, name) => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = addy

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);

        toast.success(`Copied ${name}'s address to clipboard`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })
  }

function formatNumber(number) {
  // console.log('number', number)
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0, // Remove any decimal places
  }).format(number);
}

function formatNumberETH(number, precision = 12, id) {

  let result = null 

  try {

    // console.log('number', number)
    // Convert the number to a fixed-point notation with the desired precision
    let formattedNumber = parseFloat(number).toFixed(precision);
    
    // Split the formatted number into integer and decimal parts
    let [integerPart, decimalPart] = formattedNumber.split('.');
    
    // Count leading zeros in the decimal part
    let zeroCount = 0;
    while (decimalPart[zeroCount] === '0') {
        zeroCount++;
    }
    
    // Extract the significant part of the decimal after the leading zeros
    let significantPart = decimalPart.slice(zeroCount);

    // Convert zeroCount to subscript using Unicode subscript digits
    const subscriptDigits = '₀₁₂₃₄₅₆₇₈₉';
    let subscriptCount = zeroCount.toString().split('').map(digit => subscriptDigits[digit]).join('');

    // Limit the significant part to 4 digits for readability (you can adjust as needed)
    significantPart = significantPart.slice(0, 4);

    // Format the result as "0.0₇4978" or similar
    result = `0.0${subscriptCount}${significantPart}`;

  } catch(error) {

    console.log('error', error)
    console.log('number', number)
    console.log('id', id)

  }

  return result;

}

function timeAgo(timestamp) {
  return moment.unix(timestamp).fromNow();
}

/*
  const handleDelete = (id, name) => {
        toast.success(`Removed ${name}'s Bag`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
  };
*/

function shortenNumber(num) {
    return Number(num.toPrecision(11));
}

// console.log('sortedBags', sortedBags)

useEffect(() => {
  const savedOpenCard = sortedBags.find(({ filter_id }) => getCookie(`card_${filter_id}`) === 'open');
  if (savedOpenCard) {
    setOpenCardId(savedOpenCard.filter_id);
  }
}, [sortedBags]);


function calculatePercentageChange(oldValue, newValue) {
  console.log('oldValue', oldValue)
  console.log('newValue', newValue)
    const change = ((newValue - oldValue) / oldValue) * 100;
    console.log('change', change)
    return change; // Round to 2 decimal places for readability
}

function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

function getCookie(name) {
  const cookieArr = document.cookie.split(';');
  for (let cookie of cookieArr) {
    const [key, val] = cookie.trim().split('=');
    if (key === name) {
      return val;
    }
  }
  return null;
}

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}


  const [openCardId, setOpenCardId] = useState(null);

const ExpandIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18 15l-6-6-6 6" />
  </svg>
);

const CollapseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 9l6 6 6-6" />
  </svg>
);



const toggleCard = (filter_id) => {
  let updatedOpenCardIds;

  if (openCardIds.includes(filter_id)) {
    updatedOpenCardIds = openCardIds.filter((id) => id !== filter_id);
    deleteCookie(`card_${filter_id}`); // Remove cookie if the card is closed
  } else {
    updatedOpenCardIds = [...openCardIds, filter_id];
    setCookie(`card_${filter_id}`, 'open', 7); // Save state for 7 days
  }

  setOpenCardIds(updatedOpenCardIds);
};

useEffect(() => {
  // Load cookies for open cards on first load only
  const initialOpenCardIds = sortedBags
    .filter(({ filter_id }) => getCookie(`card_${filter_id}`) === 'open')
    .map(({ filter_id }) => filter_id);

  // Only update the state if the open card IDs are different from the current state
  if (JSON.stringify(initialOpenCardIds) !== JSON.stringify(openCardIds)) {
    setOpenCardIds(initialOpenCardIds);
  }
}, [sortedBags, openCardIds]);

function shortenText(text, maxLength = 20) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

return sortedBags.length > 0 ? (
  <div className={styles.simbuysContainer}>
    {sortedBags.map(({ filter_id, items }) => (
      <div className={`${styles.buysCard} ${openCardIds.includes(filter_id) ? '' : styles.collapsed}`} key={filter_id}>
        <div className={`${styles.buysHeader} ${openCardIds.includes(filter_id) ? styles.withBorder : ''}`} onClick={() => toggleCard(filter_id)}>
          <div className={styles.buysTitle}>
            <span>{shortenText(items[0].filter_name)} ({items.length})</span>
            <span className={styles.iconWrapper}>
              {openCardIds.includes(filter_id) ? <CollapseIcon /> : <ExpandIcon />}
            </span>
          </div>
        </div>

        <div className={`${styles.collapseContent} ${openCardIds.includes(filter_id) ? styles.show : styles.hide}`}>

          {items.map((item) => {
            const percentageChange = calculatePercentageChange(item.spent, item.amount * item.current_price);
            const changeClass = percentageChange > 0 ? styles.green : styles.red;

            return (
              <div className={styles.buysContainer} key={item.id}>
                <div className={styles.iconCircle}>
                  <BiX size={17} color="#fff" onClick={() => handleDelete(item.id, item.name)} />
                </div>

                <div className={styles.buysRow}>
                  <div className={styles.label}>DATE</div>
                  <div className={`${styles.value} ${styles.positive}`}>{timeAgo(item.timestamp)}</div>
                </div>

                <div className={styles.buysRow}>
                  <div className={styles.label}>ADDRESS</div>
                  <div className={styles.valueContainer}>
                    <div className={`${styles.value} ${styles.address}`}>
                      {shortenAddress(item.address)}
                    </div>
                    <img 
                      src={copypatseIcon} 
                      onClick={() => handleCopyToClipboard(item.address, item.name)} 
                      className={styles.icon} 
                    />
                  </div>
                </div>
                
                <div className={styles.buysRow}>
                  <div className={styles.label}>TOKENa</div>
                  <div className={`${styles.value} ${styles.highlight}`}>{truncateString(item.name)}</div>
                </div>







              <div className={styles.buysRow}>
                <div className={styles.label}>INITIAL MARKETCAP</div>
                <div className={`${styles.value} ${styles.initial}`}>${formatNumber(parseFloat(item.initial_marketcap).toFixed(2), 12, item)}</div>
              </div>

 
              <div className={styles.buysRow}>
                <div className={styles.label}>CURrENt MARKETCAP</div>
                <div className={`${styles.value} ${styles.positive}`}>${formatNumber(parseFloat(item.current_marketcap).toFixed(2), 12, item)}</div>
              </div>


                            <div className={styles.buysRow}>
                <div className={styles.label}>ENTRY PRICE</div>
                <div className={`${styles.value} ${styles.initial}`}>{formatNumberETH(item.initial_price, 12, item)}</div>
              </div>

                            <div className={styles.buysRow}>
                <div className={styles.label}>CURRENT PRICE</div>
                <div className={`${styles.value} ${styles.positive}`}>{formatNumberETH(item.current_price, 12, item)}</div>
              </div>


        
  

{/*
              <div className={styles.buysRow}>
                <div className={styles.label}>INITIAL VALUE</div>
                <div className={`${styles.value} ${styles.initial}`}>{item.spent} SOL</div>
              </div>

              */}

                            <div className={styles.buysRow}>
                <div className={styles.label}>BAG</div>
                <div className={`${styles.value} ${styles.bagz}`}>{formatNumber(item.amount, 12, item)} ${item.symbol}</div>
              </div>


              <div className={styles.buysRow}>
                <div className={styles.label}>INITIALS</div>
                <div className={`${styles.value} ${styles.initial}`}>{item.spent} SOL</div>
              </div>

                <div className={styles.buysRow}>
                  <div className={styles.label}>CURRENT VALUE</div>
                  <div className={`${styles.value} ${styles.bag}`}>
                    {(item.amount * item.current_price).toFixed(2)} SOL
                  </div>
                </div>



                <div className={styles.buysRow}>
                  <div className={styles.label}>Performance</div>
                  <div className={changeClass}>
                    {percentageChange.toFixed(2)}%
                  </div>
                </div>


              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
) : <p className={styles.value}>NO SIMULATED BUYS YET</p>;
}
