import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import s from './Logs.module.scss'
import TimeAgo from 'timeago-react';
import moment from 'moment';

const TimeAgoComponent = ({ timestamp }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    // Update the current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Convert Unix timestamp to JavaScript Date object
  const dateObject = moment.unix(timestamp).toDate();

  return (
    <div>
      {/* Display the relative time using TimeAgo */}
      <TimeAgo datetime={dateObject} />

      {/* Alternatively, you can customize the display format */}
      {/* <TimeAgo datetime={dateObject} locale="en_US" formatter={(value, unit, suffix) => `${value} ${unit[0]} ${suffix}`} /> */}
    </div>
  );
};

export default function GenerateCode() {
  const logs = useSelector((state) => state.modalSlice.logs);
  const stats = useSelector((state) => state.modalSlice.stats);
  const user = useSelector((state) => state.modalSlice.user);

/*
  const { getStats } = useActions();

  const fetchDataAsync = async () => {
    try {
      await getStats(user);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchDataAsync();

    // Fetch data every 30 seconds
    const intervalId = setInterval(fetchDataAsync, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);

*/
  
  return logs !== undefined ? <CodesList logs={logs} /> : <p></p>;
}

const CodesList = ({ logs }) => {
  return (
    <div className={s.codes}>
      {/* NEW USERS */}
      <div style={{ marginTop: '70px', marginBottom: '70px' }}>
        <br />
        <h3>NEW USERS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.newUsers.length > 0 ? (
            logs.newUsers.map((user, userIdx) => (
              <li key={user.telegram_name + user.timestamp} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}>
                    <TimeAgoComponent timestamp={user.timestamp} />
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>

      {/* LOGINS */}
      <div style={{ marginBottom: '70px' }}>
        <br />
        <h3>LOGINS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.logins.length > 0 ? (
            logs.logins.map((user, userIdx) => (
              <li key={user.telegram_name + user.timestamp} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}>
                    <TimeAgoComponent timestamp={user.timestamp} />
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>

      {/* CHECKPOINTS */}
      <div>
        <br />
        <h3>CHECKPOINTS</h3>
        <br />
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {logs.checkpoints.length > 0 ? (
            logs.checkpoints.map((user, userIdx) => (
              <li key={user.telegram_name + user.timestamp} style={{ display: 'block' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  <span style={{ float: 'left', display: 'block' }}>{user.telegram_name}</span>
                  <div style={{ float: 'right' }}>
                    <TimeAgoComponent timestamp={user.timestamp} />
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li style={{ display: 'block' }}>No logs</li>
          )}
        </ul>
      </div>
    </div>
  );
};
